import React, { useState, useEffect, useRef } from "react";
import { useLocation, useMatch, useParams } from "react-router";
import { api } from "../utils/axios";
import "./qrcode.css";
import { CopyToClipboard } from "react-copy-to-clipboard"
import QRCode from "qrcode.react";
import dayjs from "dayjs";
import "./pay.css";

const showMessage = (msg) => {



    var div = document.createElement("div");

    div.innerHTML = `
    <div class="msg-b">
    <div class="msg-b-text">${msg}</div>
    </div>
    `;

    document.querySelectorAll(".msg-b").forEach(el => {
        el.remove();
    })

    document.body.appendChild(div);

    setTimeout(() => {
        document.body.removeChild(div);
    }, 1500);

}



export default () => {

    const params = useParams();

    const [order, setOrder] = useState(null);

    const [submitCardNoVisable, setSubmitCardNoVisable] = useState(false);

    const [loading, setLoading] = useState(false);

    const [errMsg, setErrMsg] = useState(null);


    const getOrderData = () => {

        api(`pay/order/detail/usdt/${params.id}`).get({}).then(ret => {
            console.log("ret=>", ret);

            if (ret.code == 200) {


                if (ret.data && ret.data.payStatus == 1 && ret.data.successUrl) {

                    window.location.replace(ret.data.successUrl);
                    return;
                }

                setOrder(ret.data);
            } else {
                setErrMsg(ret.msg);
            }

        });

    }

    useEffect(() => {
        getOrderData();



        const timer = setInterval(getOrderData, 5000);

        return () => {

            clearInterval(timer);
        }



    }, []);


    if (errMsg) {

        return (
            <div style={{ textAlign: "center", paddingTop: 20 }}>
                <div style={{ marginBottom: 10 }}>
                    <div class="fork">
                        <div class="g-right"></div>
                    </div>
                </div>
                <span style={{ color: "#999" }}>
                    {errMsg}
                </span>
            </div>
        )
    }


    if (order == null) {
        return (
            <div className="loading">
                <div className="spinner">
                    <div className="rect1"></div>
                    <div className="rect2"></div>
                    <div className="rect3"></div>
                    <div className="rect4"></div>
                    <div className="rect5"></div>
                </div>
            </div>
        )
    }

    //已支付
    if (order.payStatus == 1) {

        return (
            <div style={{ textAlign: "center", paddingTop: 20 }}>
                <div style={{ marginBottom: 10 }}>
                    <span className="check">

                    </span>
                </div>
                <span style={{ color: "green" }}>
                    payment successful
                </span>
            </div>
        )

    }

    if (order.payStatus == 2) {

        return (
            <div style={{ textAlign: "center", paddingTop: 20 }}>
                <div style={{ marginBottom: 10 }}>
                    <div class="fork">
                        <div class="g-right"></div>
                    </div>
                </div>
                <span style={{ color: "#999" }}>
                    order has expired
                </span>
            </div>
        )

    }


    return (
        <div style={{ backgroundColor: "#fff", width: "100%", height: "100%", maxWidth: 640, margin: "0 auto" }}>

            <div class="pay-body">
                <div style={{ textAlign: "center" }}>
                    {/* <img src={require("./title.png")} /> */}
                </div>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: 10 }}>
                    <span>Amount:</span>
                    <span

                        style={{
                            color: "red",
                            fontSize: 32,
                            textAlign: "center",
                            marginLeft: 5

                        }}
                    >
                        <span>
                            {order.amount}
                        </span>

                    </span>
                    <span style={{ marginLeft: 5 }}>(USDT)</span>
                </div>

                <div style={{ textAlign: "center", marginBottom: 15 }}>

                    Tron(TRC20)

                </div>

                <div style={{ marginBottom: 15, textAlign: "center", color: "#4E6EF2" }}>{order.address}</div>

                <div style={{ marginBottom: 20, display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <QRCode

                        size={150}

                        value={order.address}


                    />
                </div>
                <div style={{ marginBottom: 15 }}>
                    <div style={{ padding: 10 }}>
                        <CopyToClipboard text={order.address} onCopy={() => {
                            showMessage("Copy successfully")
                        }}>
                            <div className="btn">
                                Copy Address
                            </div>
                        </CopyToClipboard>
                    </div>
                </div>
                <div style={{ textAlign: "center" }}>

                    <div class="tip"
                        style={{ fontSize: 14, marginBottom: 5, color: "red" }}
                    >Please pay once and do not pay again</div>

                    <p class="tip" style={{ fontSize: 14 }}>effective time until：<span>{dayjs(order.expireDate).format("DD/MM/YYYY HH:mm")}</span></p>


                </div>
            </div>
        </div >
    )
}
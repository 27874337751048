import React, { useState, useEffect, useRef } from "react";
import { useLocation, useMatch, useParams } from "react-router";
import { api } from "../utils/axios";
import "./pay.css";
import { CopyToClipboard } from "react-copy-to-clipboard"


const bankNameMap = {
    "KTB":" ธ.กรุงไทย / KTB",
    "SCB":"ธ.ไทยพาณิชย์ / SCB"
};

const showMessage = (msg) => {



    var div = document.createElement("div");

    div.innerHTML = `
    <div class="msg-b">
    <div class="msg-b-text">${msg}</div>
    </div>
    `;

    document.querySelectorAll(".msg-b").forEach(el => {
        el.remove();
    })

    document.body.appendChild(div);

    setTimeout(() => {
        document.body.removeChild(div);
    }, 1500);

}

const SubmitCardNoModel = ({ visable = false, onCancel, onOk, order }) => {

    const [cardNo, setCardNo] = useState(null);

    const [loading, setLoading] = useState(false);



    if (!visable) {
        return (
            <div></div>
        );
    }


    return (
        <div style={{ position: "absolute", width: "100%", height: "100%", backgroundColor: "rgba(0,0,0,0.5)", top: 0, overflowX: "hidden" }}>
            <div style={{ position: "relative", width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>

                <div style={{ width: "80%", height: 270, backgroundColor: "#fff", bottom: 0 }}>
                    <div style={{ padding: 10, borderBottom: "1px solid #ccc" }}>
                        ยืนยันบัญชี
                        {/* {"卡号验证"} */}
                    </div>
                    <div style={{ padding: 15, paddingTop: 30, paddingBottom: 0 }}>
                        <span style={{ color: "red" }}>*</span>
                        <span>
                            กรุณากรอกหมายเลขบัญชีของคุณ เพื่อทำการชำระเงิน

                            {/* {"请输入您付款需要付款银行卡号"} */}
                        </span>
                    </div>
                    <div style={{ padding: 0, width: "100%" }}>
                        <div style={{ margin: "0 auto", padding: 15, display: "flex", position: "relative" }}>
                            <input
                                value={cardNo}
                                onChange={e => {
                                    setCardNo(e.target.value);
                                }}
                                type={"number"}
                                // autoFocus
                                style={{ width: "100%", height: 30, lineHeight: 30, paddingLeft: 10, border: "1px solid #4E6EF2" }} />
                        </div>

                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ width: "50%" }}>
                            <div style={{ padding: 10 }}>
                                <div className="btn btn-2 btn-cancel" style={{ backgroundColor: "#ccc" }} onClick={onCancel}>
                                    ยกเลิก
                                    {/* {"取消"} */}
                                </div>
                            </div>
                        </div>
                        <div style={{ width: "50%" }}>
                            <div style={{ padding: 10 }}>
                                <div className={`btn btn-2 ${loading ? "disabled" : ""}`} onClick={() => {
                                    if (!cardNo || cardNo.length < 6) {
                                        showMessage("หมายเลขบัญชีไม่ถูกต้อง");
                                        // showMessage("卡号不正确")
                                        return;
                                    }
                                    setLoading(true);
                                    api("pay/order/submit/cardno").postForm({
                                        order: order,
                                        cardNo: cardNo
                                    }).then(ret => {
                                        if (ret.code != 200) {
                                            showMessage(ret.msg);
                                            return;
                                        }
                                        onOk();
                                    }).finally(() => {
                                        setLoading(false);
                                    })

                                }} >
                                    ส่ง
                                    {/* {"提交"} */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default () => {

    const params = useParams();

    const [order, setOrder] = useState(null);

    const [submitCardNoVisable, setSubmitCardNoVisable] = useState(false);

    const [loading, setLoading] = useState(false);

    const [errMsg, setErrMsg] = useState(null);


    const getOrderData = () => {

        api(`pay/order/detail/${params.id}`).get({}).then(ret => {
            console.log("ret=>", ret);

            if (ret.code == 200) {
                setOrder(ret.data);
            } else {
                setErrMsg(ret.msg);
            }

        });

    }

    useEffect(() => {
        getOrderData();


        const timer = setInterval(getOrderData,5000);

        return ()=>{

            clearInterval(timer); 
        }
    }, []);


    if (errMsg) {

        return (
            <div style={{ textAlign: "center", paddingTop: 20 }}>
                <div style={{ marginBottom: 10 }}>
                    <div class="fork">
                        <div class="g-right"></div>
                    </div>
                </div>
                <span style={{ color: "#999" }}>
                    {errMsg}
                </span>
            </div>
        )
    }


    if (order == null) {
        return (
            <div className="loading">
                <div className="spinner">
                    <div className="rect1"></div>
                    <div className="rect2"></div>
                    <div className="rect3"></div>
                    <div className="rect4"></div>
                    <div className="rect5"></div>
                </div>
            </div>
        )
    }

    //已支付
    if (order.payStatus == 1) {

        return (
            <div style={{ textAlign: "center", paddingTop: 20 }}>
                <div style={{ marginBottom: 10 }}>
                    <span className="check">

                    </span>
                </div>
                <span style={{ color: "green" }}>
                    ชำระเงินสำเร็จ
                </span>
            </div>
        )

    }

    if (order.payStatus == 2) {

        return (
            <div style={{ textAlign: "center", paddingTop: 20 }}>
                <div style={{ marginBottom: 10 }}>
                    <div class="fork">
                        <div class="g-right"></div>
                    </div>
                </div>
                <span style={{ color: "#999" }}>
                    ปิดรับออร์เดอร์นี้แล้วนะคะ
                </span>
            </div>
        )

    }


    return (
        <div style={{ backgroundColor: "#4E6EF2", width: "100%", height: "100%", maxWidth: 640, margin: "0 auto" }}>

            <div style={{ padding: 10 }}>
                <div style={{ color: "#fff", marginBottom: 20, paddingTop: 20 }}>
                    <div style={{ fontSize: 13, textAlign: "center", fontWeight: "bold" }}>
                        กรุณาดำเนินการโอนตามลำดับต่อไปนี้ภายใน {order.time} นาที
                    </div>
                </div>

                <div style={{ backgroundColor: "#fff", borderRadius: 8 }}>
                    <div style={{ padding: 15 }}>
                        <div style={{ textAlign: "center", marginBottom: 10, paddingTop: 20 }}>
                            <span style={{ fontSize: 28, fontWeight: "bold" }}>฿ {order.amount}</span>
                        </div>
                        <div style={{ textAlign: "center", marginBottom: 20 }}>
                            <CopyToClipboard text={order.amount} onCopy={() => {
                                showMessage("คัดลอกสำเร็จ")
                            }}>
                                <a href="javascript:">
                                    คัดลอกยอดเติมเงิน
                                    {/* {"复制充值金额"} */}
                                </a>
                            </CopyToClipboard>

                        </div>
                        {
                            order.hasCardNo ? (
                                <>
                                    <div className="bank-item">
                                        <div style={{ width: "60%" }}>
                                            {/* ธนาคารผู้รับเงิน */}
                                            {"ธนาคารผู้รับผลประโยชน์"}
                                        </div>
                                        <div style={{ width: "40%", textAlign: "right" }}>
                                            {bankNameMap[`${order.bankType}`]}
                                        </div>
                                    </div>
                                    <div className="bank-item">
                                        <div style={{ width: "50%" }}>
                                            ชื่อผู้รับเงิน
                                            {/* {"收款姓名"} */}
                                        </div>
                                        <div style={{ width: "50%", textAlign: "right" }}>
                                            {order.bankUserName}
                                        </div>
                                        {/* <div style={{width:"20%"}}>
                                    <a href="javascript:" style={{marginLeft:10}}>สำเนา</a>
                                </div> */}
                                    </div>
                                    <div className="bank-item">
                                        <div style={{ width: "50%" }}>
                                            รับเลขที่บัญชี

                                            {/* {"收款卡号"} */}
                                        </div>
                                        <div style={{ width: "50%", textAlign: "right" }}>
                                            <span>{order.bankCardNo}</span>
                                            <CopyToClipboard
                                                text={order.bankCardNo}
                                                onCopy={() => {
                                                    showMessage("คัดลอกสำเร็จ")
                                                }}
                                            >


                                                <a href="javascript:" style={{ marginLeft: 15 }}>
                                                    คัดลอก
                                                    {/* {"复制"} */}
                                                </a>
                                            </CopyToClipboard>
                                        </div>

                                    </div>
                                    <div>
                                        <p style={{ color: "red" }}>
                                            กรุณาเติมเงินให้เรียบร้อยก่อนสิ้นสุดการนับถอยหลัง มิฉะนั้น จะไม่สามารถเติมเงินเข้าบัญชีได้ ผู้รับเงินนี้ถูกจำกัดการเติมเงินนี้เท่านั้น  หากคุณต้องการเติมเงิน โปรดเริ่มต้นคำสั่งซื้อใหม่อีกครั้ง

                                            {/* {"*请在倒计时结束前完成充值，否则无法成功到账，此收款人仅限此次充值，需要再充值请重新发起订单"} */}
                                        </p>
                                        <p>
                                            โปรดใช้บัญชีธนาคาร <a href="javascript:">{order.userPayBankCardNo}</a> ของคุณเพื่อชำระเงินให้เสร็จสิ้น
                                            {/* 请使用您的银行卡 <a href="javascript:">{order.userPayBankCardNo}</a> 完成付款 */}
                                        </p>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div>
                                        <div className="btn" style={{ marginBottom: 20, fontSize: 14 }} onClick={() => {
                                            setSubmitCardNoVisable(true);
                                        }}>
                                            คลิกเพื่อรับบัญชีธนาคารสำหรับชำระเงิน
                                            {/* {"获取收款银行卡号"} */}
                                        </div>
                                        {/* <div>
                                            <p style={{ color: "red", fontWeight: "bold" }}>
                                                ส่งหมายเลขบัตรธนาคารที่ใช้ชำระเงินและใช้หมายเลขบัตรที่คุณส่งมาโอนเงิน (การใช้หมายเลขบัตรอื่นจะทำให้การเติมเงินล้มเหลว)
                                            </p>
                                        </div> */}
                                    </div>
                                </>
                            )
                        }





                    </div>
                </div>
            </div>
            <SubmitCardNoModel
                visable={submitCardNoVisable}
                order={params.id}
                onCancel={() => {
                    setSubmitCardNoVisable(false);
                }}

                onOk={() => {
                    setSubmitCardNoVisable(false);
                    getOrderData();
                }}

            />
        </div>
    )
}
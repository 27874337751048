
import config from "../config";
import axios from 'axios';


axios.interceptors.request.use((config)=>{
    // config.headers["AJDX-SESSION-ID"] = localStorage.getItem("AJDX-SESSION-ID");
    // config.headers["Lang"] = localStorage.getItem("lang-config");
    return config;
});

axios.interceptors.response.use(
    (response)=>{
        // console.log("::response::",response);
        const {data,headers} = response;
        // if(headers["ajdx-session-id"]){
        //     localStorage.setItem("AJDX-SESSION-ID",headers["ajdx-session-id"]);
        // }
        if(data.code && data.code != 200){
        //    alert(data.msg?data.msg:'Request Error');

        }
    
        return response;
    },
    (error)=>{
        
        if(error.response){
              return Promise.reject(error);
        }
        // alert("Request TimeOut...");
        return Promise.reject(error);
      
    }
);


/**
 * axios 
 */
export const request = async (options)=>{
    options.withCredentials = true;
    options.url = `${config.baseApiUrl}${options.url}`;
    return axios(options).then(data=>data?data.data:{});

}

export const axiosRequest = async(options)=>{
    return axios(options);
}

export const api = (service)=>{
    
    return {
        get:async (params)=>{
            return request({
                url:`/${service}`,
                params:params
            })
        },

        delete:async (id)=>{
            return request({
                method:"DELETE",
                url:`/${service}${id?"/"+id:""}`
            })
        },
        post:async (data)=>{
            return request({
                url:`/${service}`,
                method:"POST",
                data:data
            })
        },
        put:async (data)=>{
            return request({
                url:`/${service}`,
                method:"PUT",
                data:data
            })
        },
        postForm:async (params)=>{
            return request({
                url:`/${service}`,
                method:"POST",
                params
            })
        },
        save:async(data)=>{
            return request({
                url:`/${service}`,
                method:data.id?"PUT":"POST",
                data:data
            })
        },
        query:async (url,params)=>{
            return request({
                url:`/${service}${url?url:""}`,
                params:params
            })
        },
        list:async (params)=>{
            return request({
                url:`/${service}/list`,
                params:params
            })
        },
        all:async ()=>{
            return request({
                url:`/${service}/all`,
            })
        },
        downloand:async (fileName,params)=>{
            const options = {
                withCredentials:true,
                url:`${config.baseApiUrl}/${service}`,
                method:"GET",
                params:params
            };
          
            return axiosRequest({
                ...options 
            }).then(ret=>{
                console.log(ret.headers);
                const blob = new Blob(["\uFEFF",ret.data], { type: 'application/octet-stream' })
                const blobURL = window.URL.createObjectURL(blob)
                // 创建a标签，用于跳转至下载链接
                const tempLink = document.createElement('a')
                tempLink.style.display = 'none'
                tempLink.href = blobURL;
                tempLink.setAttribute('download', fileName?fileName:decodeURIComponent(ret.headers["download-filename"]));
                // 兼容：某些浏览器不支持HTML5的download属性
                if (typeof tempLink.download === 'undefined') {
                    tempLink.setAttribute('target', '_blank')
                }
                // 挂载a标签
                document.body.appendChild(tempLink)
                tempLink.click()
                document.body.removeChild(tempLink)

                // 释放blob URL地址

                window.URL.revokeObjectURL(blobURL);
                
            })
        }
    }
}
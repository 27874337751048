import logo from './logo.svg';
import './App.css';
import { BrowserRouter,Route,Routes } from 'react-router-dom';
import Pay from './pages/Pay';
import Qrcode from './pages/Qrcode';
import VndPay from './pages/VndPay';
import USDT from './pages/USDT';



function App() {
  return (
    <BrowserRouter>
      <Routes>
          <Route key={"pay"} path="/pay/:id"  index={true} element={<Pay/>}></Route>
          <Route key={"qrcode"} path="/qrcode/:id"  index={true} element={<Qrcode/>}></Route>
          <Route key={"vndqrcode"} path="/vnd/pay/:id"  index={true} element={<VndPay/>}></Route>
          <Route key={"usdt"} path="/usdt/:id"  index={true} element={<USDT/>}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;

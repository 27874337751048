import React, { useState, useEffect, useRef } from "react";
import { useLocation, useMatch, useParams } from "react-router";
import { api } from "../utils/axios";
import "./pay.css";
import { CopyToClipboard } from "react-copy-to-clipboard"
import QRCode from "qrcode.react";
import generatePayload from "promptpay-qr";
import dayjs from "dayjs";

const showMessage = (msg) => {



    var div = document.createElement("div");

    div.innerHTML = `
    <div class="msg-b">
    <div class="msg-b-text">${msg}</div>
    </div>
    `;

    document.querySelectorAll(".msg-b").forEach(el => {
        el.remove();
    })

    document.body.appendChild(div);

    setTimeout(() => {
        document.body.removeChild(div);
    }, 1500);

}

const LoadingView = () => {

    return (
        <div className="loading">
            <div className="spinner">
                <div className="rect1"></div>
                <div className="rect2"></div>
                <div className="rect3"></div>
                <div className="rect4"></div>
                <div className="rect5"></div>
            </div>
        </div>
    )
}


const payTypes = {
    "bank": [
        { image: "/vnd/Vietcombank.png", name: "Vietcombank", title: "Ngân hàng thương mại cổ phần Ngoại thương Việt Nam" },
        { image: "/vnd/VTB.jpg", name: "VietinBank", title: "Ngân hàng Thương mại cổ phần Công Thương Việt Nam" },
        { image: "/vnd/titileBIDV.png", name: "BIDV", title: "Ngân hàng Thương mại cổ phần Đầu tư và Phát triển Việt Nam" },
        { image: "/vnd/ACB.png", name: "ACB", title: "Ngân hàng thương mại cổ phần Á Châu" },
        { image: "/vnd/vib.png", name: "VIB", title: "Ngân hàng thương mại cổ phần Quốc tế Việt Nam" },
        { image: "/vnd/shb.png", name: "SHB", title: "Ngân hàng Thương mại cổ phần Sài Gòn - Hà Nội" },
        { image: "/vnd/eximbank.png", name: "EximBank", title: "Ngân hàng thương mại cổ phần Xuất Nhập khẩu Việt Nam" },
        { image: "/vnd/hdbank.png", name: "HDBank", title: "Ngân hàng thương mại cổ phần Phát triển Thành phố Hồ Chí Minh" },
        { image: "/vnd/tpb.png", name: "TPBank", title: "Ngân hàng Thương mại Cổ phần Tiên Phong" },
        { image: "/vnd/vpb.png", name: "VPBANK", title: "Ngân hàng Thương mại Cổ phần Việt Nam Thịnh Vượng" },
        { image: "/vnd/Techcombank.jpg", name: "Techcombank", title: "Ngân hàng Thương mại Cổ phần Kỹ Thương Việt Nam" },
        { image: "/vnd/MB.png", name: "MB", title: "Ngân hàng thương mại cổ phần Quân đội" }
    ],
    "other":[
        {image:"/vnd/momo.png",name:"MoMo",title:"MoMo"},
        {image:"/vnd/titleVipay.png",name:"ViettelPay",title:"ViettelPay"},
        {image:"/vnd/titileVNPAY.png",name:"VNPAY",title:"VNPAY"},
        {image:"/vnd/zalopay.png",name:"ZaloPay",title:"ZaloPay"}

    ]
}

export default () => {

    const params = useParams();

    const [order,setOrder] = useState(null);

    const [loading,setLoading] = useState(false);

    // const [payTypes,setPayTypes] = useState([]);



    useEffect(() => {

        api("pay/vnd/order").get({orderNo:params.id}).then(ret=>{
            if(ret.code == 200){
                setOrder(ret.data);
            }
        })





    }, []);

    if(!order){
        return (
            <LoadingView/>
        )
    }

    if(order.vndPayUrl){
        
        
        window.location.replace(order.vndPayUrl);



        return <LoadingView/>;
    }


    const types = (order.channel == 22 || order.channel == 23)?payTypes.bank:payTypes.other;

    return (
        <div style={{ backgroundColor: "#f1f1f1", width: "100%", maxWidth: 640,minHeight:"100%", margin: "0 auto" }}>


            <div style={{ padding: 20, textAlign: "center", fontSize: 24, paddingTop: 10 }}>
                <span style={{ fontWeight: "bold" }}>Số tiền nạp：</span><span style={{ color: "red", fontSize: 28 }}>{order.amount}</span>
            </div>
            <div style={{ padding: 20, paddingTop: 0 }}>
                <div style={{ fontWeight: "bold", marginBottom: 20 }}>phương thức thanh toán：</div>
                <div style={{ display: "flex", flexDirection: "column" }}>

                    {
                        types.map(it => {
                            return (
                                <div
                                    className={`${loading?"disabled":""}`}
                                    key={it.name}
                                    style={{
                                        backgroundColor: "#fff",
                                        padding: 10,
                                        marginBottom: 10,
                                        borderRadius: 5,
                                        display: "flex",
                                        alignItems: "center"
                                    }}
                                    onClick={()=>{

                                        setLoading(true);

                                        api("pay/vnd/order").post({
                                            orderNo:params.id,
                                            payType:it.name
                                        }).then(ret=>{
                                            if(ret.code == 200){
                                                setOrder(null);
                                                window.location.replace(ret.data);
                                                return;
                                                
                                            }
                                            showMessage(ret.msg);

                                        }).finally(()=>{
                                            setLoading(false);
                                        })

                                    }}
                                >
                                    <img src={it.image} style={{ width: 60, height: 40, objectFit: "contain" }} />
                                    <div style={{ paddingLeft: 10, fontSize: 12 }}>
                                        {it.title}
                                    </div>
                                </div>
                            )
                        })
                    }


                </div>
            </div>

        </div >
    )
}
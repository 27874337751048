import React, { useState, useEffect, useRef } from "react";
import { useLocation, useMatch, useParams } from "react-router";
import { api } from "../utils/axios";
import "./qrcode.css";
import { CopyToClipboard } from "react-copy-to-clipboard"
import QRCode from "qrcode.react";
import generatePayload from "promptpay-qr";
import dayjs from "dayjs";

const showMessage = (msg) => {



    var div = document.createElement("div");

    div.innerHTML = `
    <div class="msg-b">
    <div class="msg-b-text">${msg}</div>
    </div>
    `;

    document.querySelectorAll(".msg-b").forEach(el => {
        el.remove();
    })

    document.body.appendChild(div);

    setTimeout(() => {
        document.body.removeChild(div);
    }, 1500);

}



export default () => {

    const params = useParams();

    const [order, setOrder] = useState(null);

    const [submitCardNoVisable, setSubmitCardNoVisable] = useState(false);

    const [loading, setLoading] = useState(false);

    const [errMsg, setErrMsg] = useState(null);


    const getOrderData = () => {

        api(`pay/order/detail/qrcode/${params.id}`).get({}).then(ret => {
            console.log("ret=>", ret);

            if (ret.code == 200) {
                setOrder(ret.data);
            } else {
                setErrMsg(ret.msg);
            }

        });

    }

    useEffect(() => {
        getOrderData();


         
        const timer = setInterval(getOrderData,5000);

        return ()=>{

            clearInterval(timer); 
        }



    }, []);


    if (errMsg) {

        return (
            <div style={{ textAlign: "center", paddingTop: 20 }}>
                <div style={{ marginBottom: 10 }}>
                    <div class="fork">
                        <div class="g-right"></div>
                    </div>
                </div>
                <span style={{ color: "#999" }}>
                    {errMsg}
                </span>
            </div>
        )
    }


    if (order == null) {
        return (
            <div className="loading">
                <div className="spinner">
                    <div className="rect1"></div>
                    <div className="rect2"></div>
                    <div className="rect3"></div>
                    <div className="rect4"></div>
                    <div className="rect5"></div>
                </div>
            </div>
        )
    }

    //已支付
    if (order.payStatus == 1) {

        return (
            <div style={{ textAlign: "center", paddingTop: 20 }}>
                <div style={{ marginBottom: 10 }}>
                    <span className="check">

                    </span>
                </div>
                <span style={{ color: "green" }}>
                    ชำระเงินสำเร็จ
                </span>
            </div>
        )

    }

    if (order.payStatus == 2) {

        return (
            <div style={{ textAlign: "center", paddingTop: 20 }}>
                <div style={{ marginBottom: 10 }}>
                    <div class="fork">
                        <div class="g-right"></div>
                    </div>
                </div>
                <span style={{ color: "#999" }}>
                    ปิดรับออร์เดอร์นี้แล้วนะคะ
                </span>
            </div>
        )

    }


    return (
        <div style={{ backgroundColor: "#fff", width: "100%", height: "100%", maxWidth: 640, margin: "0 auto" }}>

            <div class="pay-body">
                <div style={{ textAlign: "center" }}>
                    <img src={require("./title.png")} />
                </div>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: 10 }}>
                    <span>จำนวนเงินโอน：</span>
                    <span

                        style={{
                            color: "red",
                            fontSize: 32,
                            textAlign: "center",
                            marginLeft: 5

                        }}
                    >
                        <span>
                            {order.amount}
                        </span>
                        ฿
                    </span>
                </div>

                {/* <p class="tip"
                    //  style="font-size: 14px;color: red;font-weight: bold"
                    style={{
                        fontSize: 14,
                        color: "red",
                        fontWeight: "bold"
                    }}
                >กรุณาโอนตามจำนวนเงินที่ตั้งไว้ หากเปลี่ยนจำนวนเงิน เงินจะไม่เข้าบัญชี </p> */}
                <div style={{ marginBottom: 20, display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <QRCode

                        size={150}

                        value={generatePayload(order.pyId, {
                            amount: order.fpAmount
                        })}


                    />
                </div>
                <div style={{ textAlign: "center" }}>
                    <img src={require("./qr-desc.jpeg")} style={{ width: "60%" }} />
                </div>
                <div class="tip"
                    style={{ fontSize: 14, marginBottom: 5,color:"red" }}
                >กรุณาจ่ายเพียงครั้งเดียว อย่าจ่ายซ้ำ</div>

                <p class="tip" style={{ fontSize: 14 }}>เวลามีผลจนถึง：<span>{dayjs(order.expireDate).format("DD/MM/YYYY HH:mm")}</span></p>

                <div
                    // style="width:200px;margin:0 auto;"
                    style={{ width: 200, margin: "0 auto" }}

                >
                    1. บันทึกภาพหน้าจอ <br />
                    2. เปิดแอปธนาคารของคุณ <br />
                    3. สแกนรหัส QR <br />
                    4. ทำการโอนเงิน <br />
                </div>
            </div>
        </div >
    )
}

/**
 * 配置
 */
const CONFIG = {
    development: {
        baseApiUrl: "http://127.0.0.1:5081", 
        // baseApiUrl:"//172.18.1.47:5081"
   
    },
    production: {
        baseApiUrl: `//h5-api.xpay.pw`,
      
    }

}

// console.log(process.argv,process.env);

const config = CONFIG[`${process.env.NODE_ENV}`]

export default config;



